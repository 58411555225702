import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Layout, ConfigProvider, Row, Modal, Button } from "antd/es";
import { Badge } from "antd";
import ReactTimeago from "react-timeago";

import appTheme from "../theme";
import RecentResults from "./RecentResults";
import { AimOutlined, BarsOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";

import "../style/app-shelf.scss";
import { HoverProvider } from "./Hoverable";
import { deleteAllQueuedTrainingTasks, deleteTrainingTask, getQueuedTrainingTasks, setNsfw, setTrainingPriority } from "../api";
import { ArrowDownOutlined, CloseOutlined, EditOutlined, HourglassOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons/es";
import { TrainingSetup } from "./TrainingSetup";
import { Input } from "antd";
import CollapsingCard from "./CollapsingCard";
import { Slider } from "antd";
import { Dropdown } from "antd";
import { Select } from "antd";
import { Space } from "antd";

const { Header, Content } = Layout;



const App = () => {
    // const [theme, setTheme] = useState(appTheme);

    return (
        <ConfigProvider theme={appTheme}>
            <HoverProvider>
                <MainBody />
            </HoverProvider>
        </ConfigProvider>
    );
}

function useHistory() {
    const [loc, setLoc] = useState(window.location.pathname);

    useEffect(() => {
        const ctrl = new AbortController();

        window.addEventListener("popstate", ev => {
            setLoc(window.location.pathname);
        }, { signal: ctrl.signal });

        return () => ctrl.abort();
    }, [setLoc]);

    const navigateTo = ((path) => {
        window.history.pushState({}, "", path);

        // We need to manually emit this event because the browser doesn't do it.
        window.dispatchEvent(new PopStateEvent('popstate', {}));
    });

    return {
        path: loc,
        go: navigateTo,
    };
}

const Navigation = (props) => {
    const classes = props.visible ? "app-shelf" : "app-shelf-closed";

    return (
        <div className={classes}>
            <MenuButton path="/">Training</MenuButton>
            <MenuButton path="/embeddings/openai">OpenAI TVec</MenuButton>
        </div>
    )
}

const Page = (props) => {
    const { children = [], path } = props;

    const hist = useHistory();

    if (hist.path !== path) {
        return <Fragment key={path}></Fragment>
    }

    return (
        <Fragment key={path}>{children}</Fragment>
    )
}

const MenuButton = (props) => {
    const { children = [], path } = props;

    const hist = useHistory();

    const active = hist.path === path;
    const classes = active ? "app-shelf-entry active" : "app-shelf-entry";


    return (
        <div className={classes} onClick={() => hist.go(path)}>
            <AimOutlined />
            <div className="label">{children}</div>
        </div>
    )
}

const MainBody = memo(() => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [deriveArgs, setDeriveArgs] = useState(null);

    return (
        <Layout style={{ minHeight: "100%", height: '100%' }}>
            <AppHeader setDrawerVisible={setDrawerVisible} deriveArgs={deriveArgs} />

            <div className="app-container">
                <Navigation visible={drawerVisible} />

                <Layout style={{ padding: "0 8px 8px", height: '100%' }}>
                    {/* <Breadcrumb style={{ margin: "16px 0" }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Training</Breadcrumb.Item>
                        <Breadcrumb.Item>Images</Breadcrumb.Item>
                    </Breadcrumb> */}

                    <Content style={{ padding: 0, margin: 0, minHeight: 280, color: "#ccc" }}>
                        <Layout style={{ height: '100%' }}>
                            <Row gutter={[12, 12]} style={{ minHeight: 0, marginTop: 8 }}>
                                <Page path="/">
                                    <RecentResults derive={setDeriveArgs} />
                                </Page>

                                <Page path="/embeddings/openai">
                                    <OpenAIEmbeddings />
                                </Page>
                            </Row>
                        </Layout>
                    </Content>
                </Layout>
            </div>
        </Layout>
    );
});

const OpenAIEmbeddings = (props) => {
    const [records, setRecords] = useState([]);
    const [input, setInput] = useState("");
    const [threshold, setThreshold] = useState([-1, 1]);

    const addEmbedding = () => {
        fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/openai-tvec", {
            method: "POST",
            body: JSON.stringify([input]),
            headers: {
                "content-type": "application/json"
            }
        }).then(r => r.json()).then(j => {
            setRecords(all => [{ text: input, data: j.data[0].embedding }, ...all]);
            setInput("");
        });
    };

    const compareEmbeddings = (txtA, txtB) => {
        const objA = records.find(e => e.text === txtA);
        const objB = records.find(e => e.text === txtB);

        if (objA && objB && objA !== objB) {
            const diff = {
                text: `Diff comparison`,
                data: objA.data.map((f, idx) => f - objB.data[idx]),
            };

            setRecords(all => [diff, ...all]);
        }
    };

    const [lower, upper] = threshold;

    return <div className="embeddings-page">
        <div className="embeddings-setup">
            <Input
                className="embeddings-prompt"
                onChange={e => setInput(e.target.value)}
                placeholder="Text to compute embeddings of"
                addonAfter={<Button disabled={!input} type="primary" onClick={addEmbedding} icon={<BarsOutlined />} />}
            />

            {lower} &mdash; {upper}
            <Slider range min={-0.1} max={0.1} step={0.001} defaultValue={[-0.1, 0.1]} onChange={setThreshold} />
        </div>

        <div className="embeddings-container">
            {records.map(emb => (
                <EmbeddingsViewer
                    text={emb.text}
                    data={emb.data}
                    threshold={threshold}
                    allEmbeddings={records}
                    compareEmbeddings={compareEmbeddings}
                />
            ))}
        </div>
    </div>
}

const EmbeddingsViewer = (props) => {
    const { text, data, threshold, allEmbeddings, compareEmbeddings } = props;

    const processed = useMemo(() => {
        const [min, max] = threshold;
        const result = [...data];

        for (let i = 0; i < result.length; i++) {
            const v = result[i];
            if (v < min || v > max) {
                result[i] = 0;
            }
        }

        return result;
    }, [threshold, data]);

    const items = allEmbeddings.filter(o => o.text !== text).map(emb => ({
        label: emb.text,
        key: emb.text,
    }));

    const compareWith = (info) => {
        const target = allEmbeddings.find(e => e.text === info.key);
        if (target) {
            compareEmbeddings(text, target.text);
        }
    };

    return (<div className="embedding"><CollapsingCard title={text}>
        {/* <div className="embedding-text">{text}</div> */}
        <FloatHeatmap data={processed} />

        <Dropdown menu={{ items, onClick: compareWith }}>
            <Button>
                <Space>
                    Compare with...
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    </CollapsingCard>
    </div>)
}

const FloatHeatmap = (props) => {
    const { width = 48, height = 32, data } = props;
    const rows = useMemo(() => generateHeatmap(data, height, width), [data, height, width]);

    if (width * height !== data.length) {
        return <div>Expected {width * height} floats for a {width}x{height} grid, but got {data.length}.</div>
    }

    return (<div className="float-heatmap sm">
        <table>
            <thead></thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    </div>)
}

function generateHeatmap(data, height, width) {
    let rows = [];

    for (let y = 0; y < height; y++) {
        let cols = [];

        for (let x = 0; x < width; x++) {
            const f = data[y * width + x] * 1;

            cols.push(<td key={x}><div className={`v_${(f * 100).toFixed(0)}`}>{f.toFixed(2)}</div></td>);
        }

        rows.push(<tr key={y}>{cols}</tr>);
    }

    return rows;
}

const AppHeader = ({ setDrawerVisible, deriveArgs }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (deriveArgs) {
            setIsModalOpen(true);
        }
    }, [deriveArgs]);

    return <>
        <Header className="header">
            <div className="app-menu-toggle" onClick={() => setDrawerVisible(v => !v)}>
                <BarsOutlined />
            </div>

            <div className="logo">
                <span className='logo-effect'>
                    Bitgate
                </span>

                <span style={{ fontSize: 20, userSelect: 'none' }} onClick={() => setNsfw(true)}>.ai</span>
            </div>

            <div className="menu-right">
                <div className="app-plus" onClick={() => setIsModalOpen(true)}>
                    <PlusOutlined />
                </div>

                <TrainingQueue />
            </div>
        </Header>

        <TrainingSetup
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            deriveArgs={deriveArgs}
        />
    </>
}

const TrainingQueue = (props) => {
    const [queue, setQueue] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getQueuedTrainingTasks().then(setQueue);

        const interval = setInterval(() => {
            getQueuedTrainingTasks().then(setQueue);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="app-plus app-plus--invert" onClick={() => setIsModalOpen(true)}>
                <HourglassOutlined />

                <Badge count={queue.length} size="small" />
            </div>

            <TrainingQueueModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} queue={queue} setQueue={setQueue} />
        </>
    );
}

const TrainingQueueModal = (props) => {
    const { isOpen, setIsOpen, queue, setQueue } = props;
    const [viewing, setViewing] = useState(null);

    const view = (task) => {
        setViewing(task);
    }

    const edit = (task) => {
        console.log('Editing', task);
    }

    const cancel = (task) => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            deleteTrainingTask(task.key).then(() => {
                getQueuedTrainingTasks().then(setQueue);
            });
        }
    }

    const truncateQueue = () => {
        if (window.confirm('Are you sure you want to delete all queued tasks?')) {
            deleteAllQueuedTrainingTasks().then(() => {
                setIsOpen(false);
            });
        }
    }

    return (
        <Modal title="SDXL Training Queue"
            width={800}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            className="training-modal"
            footer={(orig) =>
                <TrainingQueueFooter orig={orig}>
                    <Button type="text" danger onClick={truncateQueue}>Truncate</Button>
                </TrainingQueueFooter>
            }
        >
            <div className="training-queue">
                {queue.map(task => (
                    <TrainingQueueEntry
                        key={task.key}
                        task={task}
                        view={view}
                        edit={edit}
                        cancel={cancel}
                        setQueue={setQueue}
                    />
                ))}

                <div className="execution-order-indicators">
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                </div>
            </div>
        </Modal>
    )
}

const TrainingQueueFooter = ({ orig, children }) => {
    return (
        <div className="expanded-footer">
            <div className="expanded-footer--children">
                {children}
            </div>

            <div className="expanded-footer--orig">
                {orig}
            </div>
        </div>
    );
};

const TrainingQueueEntry = (props) => {
    const { task, view, edit, cancel, setQueue } = props;

    const { data, key, processing, priority = 1 } = task;
    const { args, code, boot_file } = JSON.parse(data);

    const setPriority = (newPriority) => {
        setTrainingPriority(key, newPriority).then(() => {
            getQueuedTrainingTasks().then(setQueue);
        });
    };

    return (
        <div className="training-queue-entry">
            <div className="training-queue-entry__title-group">
                <div className="training-queue-entry__title">
                    {args.captions_file}

                    <div className="training-queue-entry__time">
                        <ReactTimeago date={key} />
                    </div>
                </div>

                <div className="training-queue-entry__subtitle">
                    {args.validation_prompt}
                </div>
            </div>

            <div className="training-queue-entry__suffix">
                <div className="training-queue-entry__actions">
                    <div className="priority-change">
                        <Button size="small" type="text" onClick={() => setPriority(priority - 1)}>-</Button>
                        <div className="priority-value">{priority}</div>
                        <Button size="small" type="text" onClick={() => setPriority(priority + 1)}>+</Button>
                    </div>

                    <Button size="small" type="primary" icon={<InfoCircleOutlined />} title="View" />
                    <Button size="small" type="primary" icon={<EditOutlined />} title="Edit" />
                    <Button size="small" type="primary" danger icon={<CloseOutlined />} title="Cancel" />
                </div>
            </div>
        </div>
    );
}


export default App;