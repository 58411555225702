import React, { useState } from "react";
import { Col, Row, Modal } from "antd/es";

import { useInterval } from "../util";
import { fetchResults, fetchStatus } from "../api";


import TrainingRun from "./TrainingRun";
import { useSettings } from "./RecentResults";

const TrainingRuns = ({ derive }) => {
    const settings = useSettings()[0];

    const [modal, contextHolder] = Modal.useModal();
    const [recent, setRecent] = useState([]);
    const [currentRuns, setCurrentRuns] = useState([]);

    // Refresh every 5 seconds
    useInterval(() => {
        const { paused, maxResults, combineImages, stepFilter } = settings;

        if (!paused) {
            fetchResults(maxResults * (combineImages ? 8 : 1), { step: stepFilter }).then(setRecent);
        }
    }, 5000, false, [settings]);

    useInterval(() => {
        fetchStatus().then(setCurrentRuns);
    }, 1000, false, []);

    const images = recent.slice(0, settings.maxResults);

    let rows = [];
    let curRow = [];
    let curRun = null;

    // Turn images into rows, breaking up by run_id
    images.forEach(img => {
        if (curRun !== img.run_id && curRow.length) {
            rows.push({ run_id: curRun, images: curRow, args: curRow[0].args, changes: [] });
            curRow = [];
        }

        if (settings.maxImages === 0 || curRow.length < settings.maxImages) {
            curRow.push(img);
        }

        curRun = img.run_id;
    });

    if (curRow.length) {
        rows.push({ run_id: curRow[0].run_id, images: curRow, args: curRow[0].args, changes: [] });
    }

    // Insert a row if the current run is not included in the most recent runs
    for (const run of currentRuns) {
        if (!run || !run.current_status) {
            continue;
        }

        const currentStatus = run.current_status;
        if (!rows.find(r => r.run_id === run.run_id)) {
            rows.unshift({ run_id: run.run_id, images: [], args: currentStatus.args, changes: [] });
        }
    }

    // Associate current runs by their run_id on the rows.
    const statusMap = Object.fromEntries(currentRuns.map(s => ([s.run_id, s])));

    // Iterate over the rows to find what properties have changed
    // for each experiment.
    for (let i = 1; i < rows.length; ++i) {
        const current = rows[i];
        const next = rows[i - 1];

        Object.keys(current.args).forEach(k => {
            if (k === 'output_dir') {
                return;
            }

            if (next.args[k] !== current.args[k]) {
                next.changes.push({
                    param: k,
                    from: current.args[k],
                    to: next.args[k],
                });
            }
        });
    }

    if (!settings.descending) {
        for (let row of rows) {
            row.images.reverse();
        }
    }

    const runs = rows.map(run => (
        <Col span={24} key={run.run_id}>
            <TrainingRun data={run} modal={modal} status={statusMap[run.run_id]} derive={derive} />
        </Col>
    ));

    return (<Row gutter={[12, 12]} style={{ marginTop: 12 }}>
        {runs}

        {contextHolder}
    </Row>);
};

export default TrainingRuns;