import React, { memo, useState } from "react";

const HoverContext = React.createContext({
    state: {
        position: null,
        component: null
    },
    setState: (s) => { }
});

export const HoverProvider = memo(function HoverProvider({ children, ...props }) {
    const [state, setState] = useState({ position: null, component: null });

    const { position, component } = state ?? {};

    let style = (!position || !component) ? { display: 'none' } : {
        transform: makeTransform(position),
    };

    return (<HoverContext.Provider value={{ state, setState }} {...props}>
        <div className="media-hover-preview" style={style}>
            {component}
        </div>

        {children}
    </HoverContext.Provider>);
});

function makeTransform({ x, y }) {
    const vw = document.documentElement.clientWidth;
    const vh = document.documentElement.clientHeight;

    const halfVw = vw / 2;
    const halfVh = vh / 2;

    const alignX = x < halfVw ? 1 : -1;
    const alignY = y < halfVh ? 1 : -1;

    x += alignX * 12;
    y += alignY * 12;

    if (x < 32) {
        x = 32;
    }

    if (y < 32) {
        y = 32;
    }

    if (x > vw - 32) {
        x = vw - 32;
    }

    if (y > vh - 32) {
        y = vh - 32;
    }

    const offsetX = alignX * 50;
    const offsetY = alignY * 50;

    return `translate(-50%, -50%) translate(${x}px, ${y}px) translate(${offsetX}%, ${offsetY}%)`;
}


export const Hoverable = memo(function Hoverable(props) {
    const { setState: setHover } = React.useContext(HoverContext);
    const { children, component, ...rest } = props;

    const onMouseOver = e => setHover({ position: { x: e.clientX, y: e.clientY }, component });
    const onMouseMove = e => setHover(data => ({ ...data, position: { x: e.clientX, y: e.clientY } }));
    const onMouseOut = e => setHover(null);

    if (!children) return children;

    // We need to forward the props into the component (listeners etc)
    // const { children: componentChildren } = children[0] || {};
    let comp = React.cloneElement(children, { ...rest, onMouseOver, onMouseMove, onMouseOut });

    return comp;
});
