import React, { } from "react";

import { UpOutlined } from "@ant-design/icons/es";
import { DownOutlined } from "@ant-design/icons";

const arrowStyle = {};

const CardTitle = (props) => {
    const { children, icon, collapsed = false, setCollapsed } = props;

    let className = "card-title";
    if (collapsed) {
        className += " collapsed";
    }

    return (
        <span className={className} onClick={() => setCollapsed?.(v => !v)}>
            {icon} <span className="title-text">{children}</span> {collapsed ? <DownOutlined style={arrowStyle} /> : <UpOutlined style={arrowStyle} />}
        </span>
    )
};

export default CardTitle;
