import React, { useState, useEffect, memo, useCallback } from "react";
import { SearchOutlined, FileSearchOutlined, FilterOutlined, StarFilled, DeleteFilled, ArrowDownOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, List, Tag } from "antd/es";
import { countImagesPerModel, deleteModel, downloadModel, findRunArgs, listRemoteModels, getWasabiFile, fetchResults } from "../api";

import CardTitle from "./CardTitle";
import { Checkbox } from "antd";
import { Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { recursiveObjectSort } from "../util";

import store from "../store";
import { ReloadOutlined } from "@ant-design/icons/es";

function pathSorter(a, b) {
    // 2024-04-30/02h40m12s/checkpoint-12000/unet
    const partsA = a.path.split('/');
    const partsB = b.path.split('/');

    const dateA = partsA[0];
    const dateB = partsB[0];

    if (dateA !== dateB) {
        return dateA.localeCompare(dateB);
    } else {
        const timeA = partsA[1];
        const timeB = partsB[1];

        if (timeA !== timeB) {
            return timeA.localeCompare(timeB);
        } else {
            const checkpointA = Number(partsA[2].substr(11));
            const checkpointB = Number(partsB[2].substr(11));

            return checkpointA - checkpointB;
        }
    }
}

const ModelStorageBrowser = ({ currentModel, setCurrentModel }) => {
    const [showFilters, setShowFilters] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [filters, setFilters] = useState({ query: '', localOnly: false, selecting: true });
    const [selectedModel, setSelectedModel] = useState({ open: false, model: null });
    const [loading, setLoading] = useState(false);

    const remoteModels = store.models.useRemoteModels();
    const setRemoteModels = store.models.useSetRemoteModels();
    const diskUsage = store.models.useDiskUsage();

    const updateModels = useCallback(() => {
        setLoading(true);
        listRemoteModels()
            .then(models => models.filter(m => m.path.split('/').length > 4).map(model => {
                const [date, time, checkpoint] = model.path.split('/');
                const path = `20${date}/${time}/${checkpoint}/unet`;
                return {
                    ...model,
                    path,
                    step: Number(checkpoint.split("checkpoint-")[1]) | 0
                };
            })).then(models => {
                models.sort((a, b) => pathSorter(b, a));
                return models;
            }).then(setRemoteModels).then(() => setLoading(false));
    }, [setRemoteModels, setLoading]);

    useEffect(() => {
        updateModels();
    }, [updateModels]);

    const totalSize = remoteModels.map(m => m.size).reduce((a, b) => a + b, 0);
    const totalSizeGb = (totalSize / 1024 / 1024 / 1024).toFixed(0);

    const bodyStyle = { padding: '0 !important' };

    if (collapsed) {
        bodyStyle.display = 'none';
    }

    const [size, diskUsed, diskFree] = diskUsage || [0, 0, 0];
    const free = (diskFree / 1024 / 1024 / 1024).toFixed(0);

    const title = <CardTitle icon={<FileSearchOutlined />} collapsed={collapsed} setCollapsed={setCollapsed}>
        Model Storage
        <Tag color="success" size={"small"} style={{ marginLeft: 4, padding: '0 4px', fontSize: 11 }}>{totalSizeGb} GB</Tag>
        <Tag color="info" size={"small"} style={{ marginLeft: 4, padding: '0 4px', fontSize: 11 }}>{free} GB free</Tag>
        <Button size="small" onClick={updateModels} disabled={loading} loading={loading} icon={<ReloadOutlined style={{ fontSize: 12 }} />} />
    </CardTitle>;

    const clearSelection = store.models.useClearSelection();

    return (
        <Card title={title} className="run-container settings-card model-storage" style={{ height: '100%' }} bodyStyle={bodyStyle}>
            {!collapsed && <Row>
                <Col span={24}>
                    <Input className="model-search-field"
                        placeholder="Search..."
                        value={filters.query}
                        onChange={e => setFilters(f => ({ ...f, query: e.target.value }))}
                        prefix={<SearchOutlined style={{ padding: '0 0' }} />}
                        addonAfter={<Button style={{ height: 'auto', width: 'auto' }} onClick={() => setShowFilters(v => !v)} type={showFilters ? "primary" : "default"} icon={<FilterOutlined style={{ padding: '0 8px' }} />} />}
                    />
                </Col>

                {showFilters && <Col span={24}>
                    <ModelFilters {...{ filters, setFilters, clearSelection }} />
                </Col>}

                <Col span={24} style={{ overflow: 'hidden' }}>
                    <div className={"model-list-wrapper" + (selectedModel.open ? " slide" : "")}>
                        <ModelList {...{ remoteModels, filters, currentModel, setCurrentModel }} onClick={m => setSelectedModel(v => ({ ...v, model: m, open: true }))} />

                        <div className="off-canvas">
                            {selectedModel.model && <ModelDetails {...{ selectedModel: selectedModel.model }} close={() => setSelectedModel(v => ({ ...v, open: false }))} />}
                        </div>
                    </div>
                </Col>
            </Row>}
        </Card>
    )
};
// PaginationConfig
const paginationConfig = {
    position: 'bottom',
    simple: true,
    align: 'center',
    size: 'small',
    showQuickJumper: false,
    totalBoundaryShowSizeChanger: 50000,
    pageSize: 20
};

const ModelList = ({ remoteModels, filters, onClick, currentModel, setCurrentModel }) => {
    const modelLookup = store.models.useModelLookup();

    const { localOnly, selecting } = filters;
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        const filter = () => {
            const filtered = [...remoteModels]
                .map(m => ({ ...m, local: modelLookup[m.path] }));

            setFiltered(localOnly ? filtered.filter(m => m.local) : filtered);
        };

        filter();
    }, [remoteModels, modelLookup, localOnly]);

    const [imageCounts, setImageCounts] = useState({});

    useEffect(() => {
        const updateCounts = () => {
            countImagesPerModel().then(setImageCounts);
        };

        updateCounts();

        const key = setInterval(() => updateCounts(), 30000);
        return () => clearInterval(key);
    }, []);

    return (
        <List
            pagination={paginationConfig}
            size={"small"}
            rowKey="path"
            loading={remoteModels.length === 0}
            dataSource={filtered}
            renderItem={(d, idx) => <ModelListItem
                data={d}
                imageCounts={imageCounts}
                key={idx}
                idx={idx}
                {...{ onClick, currentModel, setCurrentModel, selecting }}
            />}
            className="model-list"
        />
    )
};

const ModelDetails = (props) => {
    const { selectedModel, close } = props;
    const [modelArgs, setModelArgs] = useState(null);
    const [captions, setCaptions] = useState(null);

    const path = selectedModel.path.split("/checkpoint-")[0];
    const step = Number(selectedModel.path.split("checkpoint-")[1].split("/")[0]);

    useEffect(() => {
        if (selectedModel) {
            findRunArgs("projects/pixels-randomized-coa2/" + path, 1).then(args => {
                setModelArgs(recursiveObjectSort(args));

                getWasabiFile(`models/coa-finetune/${path.substr(2)}/captions.txt`).then(captions => {
                    setCaptions(captions);
                });
            });
        }
    }, [selectedModel, path, step]);

    return (
        <div className="off-canvas-content">
            <div className="off-canvas-header">
                <Button type="default" size="small" icon={<ArrowLeftOutlined />} onClick={() => close()} />
                <div>{path} <Tag>{step}</Tag></div>
            </div>

            <div className="off-canvas-body">
                <h2>Model details</h2>
                <pre>{selectedModel ? JSON.stringify(selectedModel, null, 4) : '...'}</pre>

                <h2>Invocation args</h2>
                <pre>{modelArgs ? JSON.stringify(modelArgs, null, 4) : '...'}</pre>

                <h2>Captions</h2>

                {modelArgs ? <>
                    Prefix:
                    <pre>{modelArgs.current_status.args.caption_prefix ?? <i>None</i>}</pre>
                    Suffix:
                    <pre>{modelArgs.current_status.args.caption_suffix ?? <i>None</i>}</pre>
                </> : null}

                Captions file:
                <pre>{captions ?? '...'}</pre>
            </div>
        </div>
    )
}


const ModelListItem = memo(function ModelListItem({ data, imageCounts, onClick, currentModel, setCurrentModel, selecting }) {
    const { size, path, local } = data;

    const modelKey = path.replace('/diffusion_pytorch_model.safetensors', '');
    const parts = path.split('/');
    const title = parts.slice(0, 2).join('/');
    const step = data.step;
    const numImages = imageCounts ? (imageCounts[modelKey] || 0) : 0;

    const [favourite, setFavourite] = useState(false);
    const [loading, setLoading] = useState(false);
    const [thumbnails, setThumbnails] = useState([]);

    const isSelected = store.models.use(s => s.selection.includes(modelKey));
    const select = store.models.use(s => s.select);
    const deselect = store.models.use(s => s.deselect);

    const filterImages = store.models.use(s => s.setImageFilter);

    const download = useCallback(() => {
        setLoading(true);
        console.log('Downloading', path);

        downloadModel(path.substr(2) + '/diffusion_pytorch_model.safetensors').then(() => setLoading(false));
    }, [path]);

    const deleteFiles = useCallback(() => {
        setLoading(true);
        console.log('Deleting', path);

        deleteModel(path.substr(2) + '/diffusion_pytorch_model.safetensors').then(() => setLoading(false));
    }, [path]);

    const isCurrent = modelKey === currentModel;

    const changeModel = e => {
        e.preventDefault();
        e.stopPropagation();

        setCurrentModel(modelKey);
    };

    // Download progress:
    // linear-gradient(90deg, #1a3411 50%, #00FFFF00 50%)

    const clickHandler = e => {
        const className = e.target.classList;

        if (className.contains('model-name') || className.contains('model-list-item')) {
            onClick(data);
        }
    }

    const setSelected = (ev) => ev.target.checked ? select(modelKey) : deselect(modelKey);

    // "output_dir": "/opt/jupyter/projects/pixels-randomized-coa2/24-07-24/10h27m27s",
    // useEffect(() => {
    //     fetchResults(1, { "output_dir": `projects/pixels-randomized-coa2/${modelKey}` })
    //         .then(results => {
    //             setThumbnails(results);
    //         });
    // }, [modelKey]);

    return <List.Item className={"model-list-item " + (isCurrent ? "active" : "")} onClick={clickHandler}>
        <div className="favourite-model">
            {selecting
                ? (<>
                    <Checkbox checked={isSelected} onChange={setSelected} />
                </>)
                : <StarFilled onClick={() => setFavourite(v => !v)} className={favourite ? "active" : ""} />}
        </div>

        <span className='model-name'>{title}</span>
        <Tag color={"default"} style={{ marginRight: 0 }}>{step}</Tag>

        <span>
            {local && <Checkbox checked={isCurrent} onChange={changeModel} disabled={!local} style={{ marginLeft: 8 }} />}
        </span>

        {numImages ? <Button type="link" size="small" className="model-list-num-images" onClick={() => filterImages(modelKey)}>{numImages} images</Button> : null}

        <span style={{ flexGrow: 1 }}>
            {thumbnails.length}
        </span>

        {local ? (
            <Tooltip destroyTooltipOnHide title="Delete model from disk">
                <Button type="default" danger size="small" loading={loading}
                    onClick={e => { e.preventDefault(); e.stopPropagation(); deleteFiles() }}
                    icon={<DeleteFilled />}
                />
            </Tooltip>
        ) : (<Button
            type="default"
            size="small"
            loading={loading}
            onClick={e => { e.preventDefault(); e.stopPropagation(); download() }}
            icon={<ArrowDownOutlined />}
        />)
        }

        <Tag color={"primary"} style={{ marginRight: 0 }}>
            {(size / 1024 / 1024 / 1024).toFixed(2)} GB
        </Tag>
    </List.Item>
}, isMemoEqual);

function isMemoEqual(p, n) {
    const eq = isMemoDataEqual(p.data, n.data) &&
        p.idx === n.idx &&
        p.model === n.model &&
        p.imageCounts === n.imageCounts &&
        p.currentModel === n.currentModel &&
        p.setCurrentModel === n.setCurrentModel &&
        p.selecting === n.selecting;

    return eq;
}

function isMemoDataEqual(prevData, nextData) {
    const { size: size1, path: path1, local: local1 } = prevData;
    const { size: size2, path: path2, local: local2 } = nextData;

    return size1 === size2 && path1 === path2 && local1 === local2;
}

const ModelFilters = (props) => {
    const { filters, setFilters, clearSelection } = props;

    const numSelected = store.models.use(s => s.selection.length);

    return (
        <div className="model-storage-filters">
            <Checkbox checked={filters.localOnly} onChange={e => setFilters(f => ({ ...f, localOnly: e.target.checked }))}>
                Only show local models
            </Checkbox>

            {numSelected ?
                <Button size="small" onClick={clearSelection}>Clear selection ({numSelected})</Button>
                :
                <Button size="small" disabled>Clear selection</Button>
            }
        </div>
    )
};


export default ModelStorageBrowser;
