import { theme } from 'antd';
const { darkAlgorithm } = theme;

const appTheme = {
    // TODO: fuck this shit I'll just make a simple editor panel
    algorithm: darkAlgorithm,
    token: {
        colorBgBase: '#0d1520',
        colorBgLayout: '#0d1520',
        // colorBgMask: '#ff0000', //111825
        colorBgContainer: '#111825', //121927
        // colorBgElevated: 'green',
        // colorBgSpotlight: 'blue',
        colorPrimary: '#ffcc66',
        // colorBgContainerDisabled: 'purple',
        fontSize: 15,
        // colorFillContent: '#0d1520',
        // colorFill: 'purple', //111825
        // colorFillDisabled: 'green',
        // colorFillAlter: '#00b96b',
        colorBgTextActive: 'red',
        colorPrimaryBgHover: 'red',
        colorPrimaryBg: '#0d1520', // nav background
        fontWeightStrong: 600,
        // colorText: 'red',
        colorTextBase: 'white',
        // colorTextLabel: 'blue',
        // colorTextDisabled: 'white',
        // colorTextHeading: 'aliceblue',
        // colorTextDescription: 'aqua',
        // colorTextSecondary: 'yellow',
    },
};

export default appTheme;