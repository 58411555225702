import React, { useState } from "react";

import { Card } from "antd";
import CardTitle from "./CardTitle";

const CollapsingCard = (props) => {
    const { children, title, bodyStyle, ...rest } = props;

    const [collapsed, setCollapsed] = useState(false);

    const newBodyStyle = {
        ...bodyStyle,
        display: collapsed ? 'none' : undefined,
    };

    return (
        <Card
            title={<CardTitle collapsed={collapsed} setCollapsed={setCollapsed}>{title}</CardTitle>}
            bodyStyle={newBodyStyle}
            {...rest}
        >
            {collapsed ? null : children}
        </Card>
    )
};

export default CollapsingCard;
