import React, { memo, useState } from "react";

import { LoadingOutlined, ToolFilled, SettingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Row, Image, Card, Modal, Button, notification, Space } from "antd/es";

import ArgsEditor from "./ArgumentEditor";
import ValidationImage from "./ValidationImage";

import { friendlyName, paramClass } from "../util";
import { useSettings } from "./RecentResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/sharp-light-svg-icons";
import { CopyOutlined, DeleteOutlined, UpOutlined } from "@ant-design/icons/es";
import { fetchRunArgs, putRunArgs } from "../api";

const { PreviewGroup } = Image;

function runContainerPropsAreEqual(prev, next) {
    const { data, status } = prev;
    const { data: data2, status: status2 } = next;

    return JSON.stringify(data) === JSON.stringify(data2) && JSON.stringify(status) === JSON.stringify(status2);
}

const TrainingRun = memo(function TrainingRun(props) {
    const { data, modal, status, derive } = props;
    const { args, images, changes = [] } = data;

    const [paramsModal, contextHolder] = Modal.useModal();
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Card className="run-container" title={<RunCardTitle {...{ status, args, images, changes, setCollapsed, derive }} openModal={v => paramsModal.info(v)} />}>
            <PreviewGroup preview={{ toolbarRender: () => <></>, countRender: () => <></> }}>
                {!collapsed && (
                    <Row gutter={[0, 0]}>
                        {images.map(r => <ValidationImage key={r.run_id + r.step} data={r} modal={modal} />)}
                    </Row>
                )}
            </PreviewGroup>

            {contextHolder}
        </Card>
    );
}, runContainerPropsAreEqual);

const RunCardTitle = (props) => {
    const { images, args, status, setCollapsed, changes, derive } = props;
    const { pinnedParams } = useSettings()[0];
    const [showArgs, setShowArgs] = useState(false);
    const run_id = status ? status.run_id : (images && images.length ? images[0].run_id : null);

    const openModal = () => props.openModal({
        title: "Experiment: " + run_id,
        width: 800,
        maskClosable: true,
        transitionName: null,
        icon: null,
        style: { maxHeight: '80vh' },
        content: <ArgsEditor runId={run_id} />
    });

    const titleStr = args.output_dir.replace('projects/pixels-randomized-coa2/', '').replace("/opt/jupyter", "");

    const [notif, contextHolder] = notification.useNotification();

    const unabortRun = async () => {
        const runArgs = await fetchRunArgs(run_id);
        runArgs.abort = false;
        await putRunArgs(run_id, runArgs);

        notif.success({
            message: 'Run un-aborted',
            description: 'The run will continue training.'
        });
    };

    const abortRun = async () => {
        if (window.confirm('Are you sure you want to abort this run?')) {
            const runArgs = await fetchRunArgs(run_id);
            runArgs.abort = true;
            await putRunArgs(run_id, runArgs);

            notif.success({
                message: 'Run aborted',
                description: 'The run will be aborted within the next 50 training steps.',
                key: run_id,
                btn: (<Space>
                    <Button type="link" size="small" onClick={unabortRun}>
                        Undo
                    </Button>
                    <Button type="primary" size="small" onClick={() => notif.destroy(run_id)}>
                        Ok
                    </Button>
                </Space>)
            });
        }
    }

    const deriveRun = () => derive({ ...args });

    return (<>
        {contextHolder}
        <span className="btn-edit-args" onClick={openModal}>
            <SettingOutlined />
        </span>

        <span className="btn-edit-args" onClick={deriveRun} style={{ marginLeft: 4 }}>
            <CopyOutlined />
        </span>

        <span className="title-text" onClick={() => setCollapsed(v => !v)}>
            {titleStr} {args.nsfw && <FontAwesomeIcon icon={faWarning} />} {status && <> <LoadingOutlined /> {status.current_status.step} {(status.current_status.vmem_kb / 1024 / 1024).toFixed(1)}/{(status.current_status.vmem_kb_rsvd / 1024 / 1024).toFixed(1)} GB</>}
            <span className="num-changes" onClick={e => { e.preventDefault(); e.stopPropagation(); setShowArgs(v => !v) }}>
                <ToolFilled /> {changes.length}
            </span>

            <span className="pinned-params">
                {pinnedParams.map(pp => (
                    <span key={pp} className={"pinned-param " + paramClass(changes, pp)}>
                        {friendlyName(pp)}: {(args[pp] ?? '').toString()}
                    </span>
                ))}
            </span>
        </span>

        {status ?
            <>
                <span className='step-status'>
                    Step #{status.current_status.step}{' '}
                </span>

                <Button danger icon={<DeleteOutlined />} size="small" type="text" onClick={abortRun} />
            </>
            :
            <span className='step-status'>Finished</span>
        }

        {showArgs ? <div className="title-params-wrapper">
            <div className="title-params">
                {changes.map(c => (
                    <div key={c.param} className="change-item">
                        <InfoCircleOutlined /> <b>{c.param}</b> set to <pre title={c.to}>{c.to + ''}</pre> (was <pre title={c.from} className="muted">{c.from + ''}</pre>)
                    </div>
                ))}
            </div>
        </div> : null}
    </>);
}




export default TrainingRun;