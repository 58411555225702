import React from "react";
import { Image } from "antd/es";


// Image element which waits for itself to render, and then grabs its own size. Uses that size
// to pick the most appropriate image variant.
const ResponsiveImage = ({ src, width: elemWidth, ...rest }) => {
    const ref = React.useRef(null);
    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
        setWidth(ref.current.clientWidth);

        const r = new ResizeObserver(() => setWidth(ref.current.clientWidth));
        r.observe(ref.current);

        return () => r.disconnect();
    }, [ref, setWidth]);

    // The possible resolutions to choose from
    const resolutions = [256, 512, 768, 1024];

    // If the width is over 1024, just go for the max resolution
    let picked = resolutions[resolutions.length - 1];
    for (let i = 0; i < resolutions.length; ++i) {
        if (width < resolutions[i]) {
            picked = resolutions[i];
            break;
        }
    }

    const url = `https://training.bitgate.ai/cdn-cgi/image/format=auto,fit=contain,width=${picked}/${src}`;

    return <div ref={ref} style={{ width: elemWidth }}>
        {width && <Image src={url} {...rest} preview={{ movable: false, src }} />}
    </div>;
}


export default ResponsiveImage;
