import { DeleteOutlined, RightOutlined } from "@ant-design/icons/es";
import { notification, Collapse } from "antd";
import { Button, Modal } from "antd";
import { memo, useEffect, useMemo, useState } from "react";
import { queueTraining } from "../api";
import { FILTERED_ARGS, DEFAULT_CODE, NAMED_DATASETS, OBSOLETE_ARGS, HIDDEN_ARGS, DATASET_ARGS } from "../datasets";


const TrainingSetup = memo(function TrainingSetup({ isOpen, setIsOpen, deriveArgs }) {
    const [args, setArgs] = useState(JSON.stringify(FILTERED_ARGS, null, 4));
    const [jsonError, setJsonError] = useState(null);
    const [bootFile, setBootFile] = useState("train-lora.py");
    const [code, setCode] = useState([...DEFAULT_CODE]);
    const [submitting, setSubmitting] = useState(false);
    const [dataset, setDataset] = useState(Object.keys(NAMED_DATASETS)[0]);

    useEffect(() => {
        if (deriveArgs) {
            setArgs(old => {
                try {
                    const combined = {
                        ...FILTERED_ARGS,
                        ...JSON.parse(old),
                        ...(Object.fromEntries(Object.entries(deriveArgs).filter(([k, v]) => Object.keys(FILTERED_ARGS).includes(k))))
                    };

                    return JSON.stringify(combined, null, 4);
                } catch (e) {
                    return JSON.stringify({ ...FILTERED_ARGS, deriveArgs }, null, 4);
                }
            });
        }
    }, [deriveArgs]);

    const combinedArgs = useMemo(() => {
        try {
            return {
                ...OBSOLETE_ARGS,
                ...HIDDEN_ARGS,
                ...DATASET_ARGS,
                ...FILTERED_ARGS,
                ...JSON.parse(args),
                ...NAMED_DATASETS[dataset]
            };
        } catch (e) { }
    }, [args, dataset]);

    const [notif, contextHolder] = notification.useNotification();

    const changeArgs = (e) => {
        setArgs(e.target.value);

        try {
            JSON.parse(e.target.value);
            setJsonError(null);
        } catch (e) {
            setJsonError("Invalid JSON: " + e.message);
        }
    };

    const reformat = () => {
        try {
            setArgs(JSON.stringify(JSON.parse(args), null, 4));
            setJsonError(null);
        } catch (e) {
            setJsonError("Invalid JSON: " + e.message);
        }
    };

    const revertArgs = () => {
        setArgs(JSON.stringify(FILTERED_ARGS, null, 4));
        setJsonError(null);
    };

    const beginTraining = () => {
        const body = {
            args: combinedArgs,
            code,
            boot_file: bootFile
        };

        setSubmitting(true);

        queueTraining(body).then(() => {
            setIsOpen(false);
            setSubmitting(false);

            notif.success({
                message: 'Training started',
                description: 'The training process has been queued and will start shortly.'
            });
        });
    };

    const close = () => {
        setIsOpen(false);
    };

    const items = [
        {
            key: 1,
            label: "Invocation",
            className: "collapse-body",
            children: <Invocation bootFile={bootFile} setBootFile={setBootFile} />
        },
        {
            key: 2,
            label: "Code bundle",
            className: "collapse-body",
            children: <CodeBundle code={code} setCode={setCode} />
        },
        {
            key: 3,
            label: "Dataset",
            className: "collapse-body",
            children: <DatasetEditor dataset={dataset} setDataset={setDataset} args={args} setArgs={setArgs} />
        },
        {
            key: 4,
            label: "Training Parameters",
            className: "collapse-body no-padding",
            children: <FreeformParameters args={args} changeArgs={changeArgs} reformat={reformat} />
        },
        {
            key: 5,
            label: "Combined Parameters",
            className: "collapse-body no-padding",
            children: <FreeformParameters readonly args={JSON.stringify(combinedArgs, null, 4)} changeArgs={() => { }} reformat={() => { }} />
        }
    ];

    return (
        <Modal title="Configure SDXL Training"
            okText={!jsonError ? "Start Training" : "Invalid JSON"}
            footer={(orig, _e) => <ExpandedFooter jsonError={jsonError} orig={orig} revertArgs={revertArgs} />}
            okButtonProps={{ disabled: jsonError || submitting, icon: <RightOutlined /> }}
            width={800}
            open={isOpen}
            onOk={beginTraining}
            onCancel={close}
            className="training-modal"
        >
            {contextHolder}

            <div className="training-form args-editor">
                <Collapse
                    accordion
                    items={items}
                    defaultActiveKey={"1"}
                    size="small"
                />
            </div>
        </Modal>
    );
});

const FreeformParameters = (props) => {
    const { args, changeArgs, reformat, readonly = false } = props;
    return (
        <textarea value={args} onChange={changeArgs} rows={32} onBlur={reformat} readOnly={readonly} />
    );
};

const DatasetEditor = (props) => {
    const { dataset, setDataset, args, setArgs } = props;

    return (<>
        <select value={dataset} onChange={e => setDataset(e.target.value)}>
            {Object.keys(NAMED_DATASETS).map(d => <option key={d} value={d}>{d}</option>)}
        </select>

        <br />

        <div className="dataset-params compressed-table">
            <table>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Value</th>
                    </tr>
                </thead>

                <tbody>
                    {Object.entries(NAMED_DATASETS[dataset]).map(([k, v], i) => (
                        <tr key={k}>
                            <td>{k}</td>

                            <td className="code-url">
                                <input value={v} onChange={e => {
                                    const newArgs = { ...args };
                                    newArgs[k] = e.target.value;
                                    setArgs(newArgs);
                                }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>)
}

const Invocation = (props) => {
    const { bootFile, setBootFile } = props;

    return (
        <div className="form-arg">
            <label>Boot file</label>
            <input value={bootFile} onChange={e => setBootFile(e.target.value)} />
        </div>
    )
}

const CodeBundle = (props) => {
    const { code, setCode } = props;

    return (<div className="code-bundle compressed-table">
        <table>
            <thead>
                <tr>
                    <th>Filename</th>
                    <th>URL</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                {code.map((c, i) => (
                    <tr key={i}>
                        <td>
                            <input value={c.filename} onChange={e => {
                                const newCode = [...code];
                                newCode[i].filename = e.target.value;
                                setCode(newCode);
                            }} />
                        </td>

                        <td className="code-url">
                            <input value={c.url} style={{ margin: 0, flex: 1 }} onChange={e => {
                                const newCode = [...code];
                                newCode[i].url = e.target.value;
                                setCode(newCode);
                            }} />
                        </td>

                        <td>
                            <Button icon={<DeleteOutlined />} onClick={() => setCode(code.filter((_, j) => j !== i))} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>);
}

const ExpandedFooter = ({ jsonError, orig, revertArgs }) => {
    return (
        <div className="expanded-footer">
            {jsonError && <div className="json-error">{jsonError}</div>}

            <div className="expanded-footer--children">
                <Button type="link" onClick={revertArgs}>Revert</Button>
            </div>

            <div className="expanded-footer--orig">
                {orig}
            </div>
        </div>
    );
};

export { TrainingSetup };