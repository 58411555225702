import React, { useState } from "react";
import { friendlyName, RELEVANT } from "../util";
import { useSetting, useSettings } from "./RecentResults";

import { CloseOutlined, SettingFilled, SettingOutlined } from "@ant-design/icons";
import { Card, Row, Col, Slider, InputNumber, Checkbox, Select } from "antd/es";
import CardTitle from "./CardTitle";

const SettingsCard = () => {
    const [settings, setSettings] = useSettings();
    const setSetting = (key, val) => setSettings(s => ({ ...s, [key]: val }));

    return (<Card title={<CardTitle icon={<SettingOutlined />}>Settings</CardTitle>} className="run-container settings-card">
        <Row gutter={[4, 8]} justify={"start"} align={"middle"}>
            <Col xxl={24}>
                <div>
                    Preview:{' '}{settings.imgIdx + 1}
                    <span style={{ display: "inline-block", float: 'right' }}>
                        <BooleanSetting name="combineImages">Combine&nbsp;images</BooleanSetting>
                    </span>
                </div>

                <Slider disabled={settings.combineImages} included={false} value={settings.imgIdx + 1} min={1} max={8} onChange={value => setSetting("imgIdx", value - 1)} />
            </Col>

            <Col flex="1">
                Max results:{'  '}
                <InputNumber min={8} max={3600} step={8} value={settings.maxResults} onChange={v => setSetting("maxResults", v)} />
            </Col>

            <Col flex="1">
                Img/run:{'  '}
                <InputNumber min={0} step={1} value={settings.maxImages} onChange={v => setSetting("maxImages", v)} />
            </Col>

            <Col flex="1">
                <Checkbox checked={!settings.descending} onChange={e => setSetting("descending", !e.target.checked)}>Reverse</Checkbox>
            </Col>

            <Col flex="1" >
                <Select value={settings.previewMultiplier} style={{ minWidth: 100 }} onChange={v => setSetting("previewMultiplier", v)}>
                    <Select.Option value={1}>Small</Select.Option>
                    <Select.Option value={2}>Medium</Select.Option>
                    <Select.Option value={3}>Large</Select.Option>
                </Select>
            </Col>

            <Col flex="1 1">
                <Select
                    mode="multiple"
                    style={{ minWidth: 220, width: '100%' }}
                    onChange={v => setSetting("pinnedParams", v)}
                    value={settings.pinnedParams}
                    placeholder="Pinned parameters"
                >
                    {RELEVANT.slice()
                        // Deduplicate:
                        .filter((v, i, a) => a.indexOf(v) === i)
                        .sort((a, b) => friendlyName(a).localeCompare(friendlyName(b)))
                        .map((h) => (
                            <Select.Option key={h} value={h}>{friendlyName(h)}</Select.Option>
                        ))}
                </Select>
            </Col>

            {/*<Col flex="1">*/}
            {/*    <BooleanSetting name="hideTime">Timestamp</BooleanSetting>*/}
            {/*</Col>*/}

            <Col flex="0 1">
                <BooleanSetting name="paused">Pause</BooleanSetting>
            </Col>
        </Row>
    </Card>)
}


const BooleanSetting = ({ name, inverted = false, children = [] }) => {
    const [value, setValue] = useSetting(name);

    return (<Checkbox checked={inverted ? !value : value} onChange={e => setValue(inverted ? !e.target.checked : e.target.checked)}>
        {children}
    </Checkbox>)
}

const FloatingSettingsEditor = (props) => {
    const [expanded, setExpanded] = useState(false);

    const icon = expanded ? <CloseOutlined /> : <SettingFilled />;

    return (
        <div className="theme-editor">
            {expanded && <div className="theme-editor-body">
                <SettingsCard />
            </div>}

            <div className="theme-editor-toggle" onClick={() => setExpanded(v => !v)}>
                {icon}
            </div>
        </div>
    )
}

export default FloatingSettingsEditor;